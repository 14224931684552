import AccordionItem from './AccordionItem';
import { Slide } from "react-reveal";

const About = () => {
    return (
        <div className="box-about">
            <div className="abouter">
            <Slide right>
                <AccordionItem
                    preguntaId="cuestion.1"
                    respuestaId="respuesta.1"
                />
                 </Slide>
                 
                 <Slide left>
                <AccordionItem
                    preguntaId="cuestion.2"
                    respuestaId="respuesta.2"
                />
                </Slide>

                <Slide right>
                <AccordionItem
                    preguntaId="cuestion.3"
                    respuestaId="respuesta.3"
                />
                </Slide>

                <Slide left>
                <AccordionItem
                    preguntaId="cuestion.4"
                    respuestaId="respuesta.4"
                />
                </Slide>

                <Slide right>
                 <AccordionItem
                    preguntaId="cuestion.5"
                    respuestaId="respuesta.5"
                />
                </Slide>

                  <Slide left>
                <AccordionItem
                    preguntaId="cuestion.6"
                    respuestaId="respuesta.6"
                />
                 </Slide>

                 <Slide right>
                 <AccordionItem
                    preguntaId="cuestion.7"
                    respuestaId="respuesta.7"
                />
                 </Slide>

                 <Slide left>
                <AccordionItem
                    preguntaId="cuestion.8"
                    respuestaId="respuesta.8"
                />
                </Slide>
                
                <Slide right>
                 <AccordionItem
                    preguntaId="cuestion.9"
                    respuestaId="respuesta.9"
                />
                </Slide>

                <Slide left>
                <AccordionItem
                    preguntaId="cuestion.10"
                    respuestaId="respuesta.10"
                />
                </Slide>
                <Slide right>
                 <AccordionItem
                    preguntaId="cuestion.11"
                    respuestaId="respuesta.11"
                />
                </Slide>

                <Slide left>
                <AccordionItem
                    preguntaId="cuestion.12"
                    respuestaId="respuesta.12"
                />
                </Slide>
                <Slide right>
                 <AccordionItem
                    preguntaId="cuestion.13"
                    respuestaId="respuesta.13"
                />
                </Slide>

                <Slide left>
                <AccordionItem
                    preguntaId="cuestion.14"
                    respuestaId="respuesta.14"
                />
                </Slide>
            </div>
        </div>
    );
};

export default About;
