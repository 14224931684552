import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../Home.css';
import logo from '../images/LOGO.png';
import Coinmarketcap from "../images/5cc1d00679bc362c2aca3ec64ccb6370.png";
import Gekoterminal from "../images/e881e884485dccf4ec69f3962799c5ae.png";
import Panckake from "../images/e029525dae8c17a4394e93ee43f41878.png";
import dextool from "../images/afcbba70808073d49b30eae612928c26.png";
import dexscreen from "../images/unnamed.png";
import dexview from "../images/58d23d5197f716116f9e6121e4daa725.png";
import { FormattedMessage } from 'react-intl';
import TokenomicsChart from './TokenomicsChart';
import RoadToMapChart from './RoadToMapChart';
import { Fade, Slide } from "react-reveal";

const Home = () => {
  const contractCopyAddress = "0x759b...9aC3C";
  const CopyAddress = "0x759bF88A0Df2dCA660109E2DD013aABaeAC9aC3C"; // Aquí deberías colocar la dirección completa

  useEffect(() => {
    const element = document.querySelector(".classparaspan");

    const handleAnimationIteration = () => {
      // Desactiva la animación y espera 100 milisegundos antes de volver a activarla
      element.style.animation = "none";
      setTimeout(() => {
        void element.offsetWidth; // Trigger reflow
        element.style.animation = "typing 6s steps(30), blink 3s infinite step-end alternate";
      }, 500);
    };

    element.addEventListener("animationiteration", handleAnimationIteration);

    return () => {
      element.removeEventListener("animationiteration", handleAnimationIteration);
    };
  }, []);

  const copyToClipboard = (e) => {
    e.preventDefault();
    try {
      navigator.clipboard.writeText(CopyAddress);
      console.log("¡Dirección del contrato copiada!");
    } catch (error) {
      console.error("Error al copiar la dirección del contrato:", error);
    }
  };

  return (
    <div className="box-home">
      <div className="home">
      <Fade top>
        <img src={logo} alt="logo" />
        </Fade>
        <div className="conteinercontrato">
          <div className="classparaspan">
            <span className="typing-animation">
              {contractCopyAddress}
            </span>
          </div>
          <div className="button-container">
            <div className="Coppy tu-clase">
              <button onClick={copyToClipboard}>
                <FormattedMessage id="Home.copy" defaultMessage="Copiar" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="perraputainfameputa">
        <div className="perraputainfameputa-track">
          <div className="slide-containerputa">
            <div className="slideputita2">
              <a href="https://coinmarketcap.com/dexscan/" target="_blank" rel="noopener noreferrer">
                <div className="image-wrapper">
                  <img src={Coinmarketcap} alt="Coinmarketcap" />
                </div>
              </a>
            </div>
            <div className="slideputita2">
              <a href="https://www.geckoterminal.com/" target="_blank" rel="noopener noreferrer">
                <div className="image-wrapper">
                  <img src={Gekoterminal} alt="Gekoterminal" />
                </div>
              </a>
            </div>
            <div className="slideputita2">
              <a href="https://pancakeswap.finance/" target="_blank" rel="noopener noreferrer">
                <div className="image-wrapper">
                  <img src={Panckake} alt="Pancakeswap" />
                </div>
              </a>
            </div>
            <div className="slideputita2">
              <a href="https://www.dexview.com/" target="_blank" rel="noopener noreferrer">
                <div className="image-wrapper">
                  <img src={dexview} alt="Dexview" />
                </div>
              </a>
            </div>
            <div className="slideputita2">
              <a href="https://www.dextools.io/" target="_blank" rel="noopener noreferrer">
                <div className="image-wrapper">
                  <img src={dextool} alt="Dextools" />
                </div>
              </a>
            </div>
            <div className="slideputita2">
              <a href="https://dexscreener.com/" target="_blank" rel="noopener noreferrer">
                <div className="image-wrapper">
                  <img src={dexscreen} alt="Dexscreener" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    <ul className="ulminer">
    <li className="blue">
        <Link to="/miner" data-text="&nbsp;MINER">&nbsp;MINER&nbsp;</Link>
    </li>
    </ul>
      <Slide left>
      <TokenomicsChart />
      </Slide>
      <Slide right>
      <RoadToMapChart />
      </Slide>
    </div>
  );
};

export default Home;
