import React, { useState, useEffect } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'; // Iconos de flechas
import '../Carousel.css';

const Carousel = ({ items }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % items.length);
        }, 23000); // Cambia cada 23 segundos

        return () => clearInterval(interval); // Limpia el intervalo al desmontar el componente
    }, [items.length]);

    return (
        <div className="carousel">
            <div className="carousel-item">
                {items[currentIndex]}
            </div>
            <div className="carousel-controls">
                <FaArrowLeft onClick={() => setCurrentIndex((currentIndex - 1 + items.length) % items.length)} />
                <FaArrowRight onClick={() => setCurrentIndex((currentIndex + 1) % items.length)} />
            </div>
            <div className="carousel-dots">
                {items.map((_, index) => (
                    <span
                        key={index}
                        className={`carousel-dot ${index === currentIndex ? 'active' : ''}`}
                        onClick={() => setCurrentIndex(index)}
                    ></span>
                ))}
            </div>
        </div>
    );
};

export default Carousel;