import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers'; // Asegúrate de tener ethers.js instalado correctamente
import { contractAddress, contractToken } from './ContractInfo';
import { useAccount, useContractRead, useContractWrite } from 'wagmi';
import { bsc } from 'wagmi/chains';
import { FormattedMessage, useIntl } from 'react-intl';
import '../Stake.css';
import { Slide } from "react-reveal";

const Stake = () => {
  const intl = useIntl();
  const { address } = useAccount();
  const [amount, setAmount] = useState('');
  const [amountInWei, setAmountInWei] = useState('');
  const [rewardBalance, setRewardBalance] = useState('0'); // Estado local para las recompensas ganadas

  const { data: balanceinevest } = useContractRead({
    abi: contractToken,
    address: contractAddress,
    functionName: 'getRemainingtoken',
    args: [address],
    chainId: bsc.id,
  });

  const { data: balanceRewarstake } = useContractRead({
    abi: contractToken,
    address: contractAddress,
    functionName: 'getCurrentRewardPerSecond',
    chainId: bsc.id,
    account: address,
  });

  const { data: contractbalance } = useContractRead({
    abi: contractToken,
    address: contractAddress,
    functionName: 'contractBalance',
    chainId: bsc.id,
    account: address,
  });

  const { data: tlvStake } = useContractRead({
    abi: contractToken,
    address: contractAddress,
    functionName: 'getTotalStakedTokens',
    chainId: bsc.id,
    account: address,
  });

  useEffect(() => {
    if (balanceRewarstake) {
      const formattedBalance = formatWeiToEther(balanceRewarstake);
      setRewardBalance(formattedBalance);
    }
  }, [balanceRewarstake]);

  const formatWeiToEther = (value) => {
    if (value) {
      return ethers.formatEther(value);
    }
    return '0';
  };

  const formatWeiToEtherSin = (value) => {
    if (value) {
      // Convert the value from Wei to Ether and truncate to remove decimals
      return Math.floor(parseFloat(value.toString()) / 1e18);
    }
    return '0';
  };

  // Función para manejar el cambio de cantidad
  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmount(value);
    // Convertir a wei usando ethers.js
    if (value) {
      try {
        const valueInWei = ethers.parseUnits(value, 'ether').toString();
        setAmountInWei(valueInWei);
      } catch (error) {
        console.error("Error converting to wei:", error);
      }
    } else {
      setAmountInWei('');
    }
  };

  const { write: increaseAllowance } = useContractWrite({
    abi: contractToken,
    address: contractAddress,
    functionName: 'increaseAllowance',
    args: [
      '0x759bF88A0Df2dCA660109E2DD013aABaeAC9aC3C',
      '5000000000000000000000000000000000000000000000000',
    ],
    chainId: bsc.id,
  });

  const { write: claimReward } = useContractWrite({
    abi: contractToken,
    address: contractAddress,
    functionName: 'claimReward',
    args: [],
    chainId: bsc.id,
  });

  const { write: withdraw } = useContractWrite({
    abi: contractToken,
    address: contractAddress,
    functionName: 'withdraw',
    args: [],
    chainId: bsc.id,
  });

  const { write: stake } = useContractWrite({
    abi: contractToken,
    address: contractAddress,
    functionName: 'stake',
    args: [amountInWei],
    chainId: bsc.id,
  });

  return (
    <div className="Stake-container">
      <div className="StakE">
        <Slide left>
          <p className="letraszr" data-label={intl.formatMessage({ id: "stake.balanceInvertido" })}>
            {formatWeiToEtherSin(balanceinevest)} PESOS
          </p>
        </Slide>
        <Slide right>
          <p className="letraszr" data-label={intl.formatMessage({ id: "stake.ganancias" })}>
            {rewardBalance} PESOS {/* Mostrar balanceRewarstake actualizado en tiempo real */}
          </p>
        </Slide>
        <Slide left>
          <p className="letraszr" data-label={intl.formatMessage({ id: "stake.balanceContrato" })}>
            {formatWeiToEtherSin(contractbalance)} PESOS
          </p>
        </Slide>
        <Slide right>
          <p className="letraszr" data-label={intl.formatMessage({ id: "stake.tlv" })}>
            {formatWeiToEtherSin(tlvStake)} PESOS
          </p>
        </Slide>
      </div>
      <div className="huevosre">
        <button className="ButtomStake" onClick={() => increaseAllowance()}><FormattedMessage id="Stake.buttom" defaultMessage="Aprobar" /></button>
        <input className="InputStake"
          type="number"
          value={amount}
          onChange={handleAmountChange}
          placeholder={intl.formatMessage({ id: "stake.placeholder" })}
        />
        <button className="ButtomStake tu-clase" onClick={() => stake()}><FormattedMessage id="Stake.buttom1" defaultMessage="Apostar" /></button>
        <button className="ButtomStake tu-clase" onClick={() => claimReward()}><FormattedMessage id="Stake.buttom2" defaultMessage="Reclamar" /></button>
        <button className="ButtomStake tu-clase" onClick={() => withdraw()}><FormattedMessage id="Stake.buttom3" defaultMessage="Retirar" /></button>
      </div>
    </div>
  );
};

export default Stake;
