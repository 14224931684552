import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, LineElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useIntl, FormattedMessage } from 'react-intl';
import '../RoadToMapChart.css';
import TimelineItem from './Timeline';


// Registrar elementos y plugins de Chart.js
Chart.register(LineElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, ChartDataLabels);

const RoadToMapChart = () => {
  const intl = useIntl();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Función para detectar el tamaño de la pantalla y actualizar isMobile
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
    };

    // Escuchar el evento de cambio de tamaño de la ventana
    window.addEventListener('resize', handleResize);

    // Inicializar isMobile en el montaje inicial
    handleResize();

    // Limpiar el event listener en la limpieza del efecto
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const data = {
    labels: [
      intl.formatMessage({id: "chart.Yeart0"}),
      intl.formatMessage({id: "chart.Yeart1"}),
      intl.formatMessage({id: "chart.Yeart2"}),
      intl.formatMessage({id: "chart.Yeart3"})
    ],
    datasets: [
      {
        label: intl.formatMessage({id: "chart.RoadMap"}),
        data: [300, 240, 192, 172.8], // Datos simbólicos
        borderColor: '#FF5733', // Color de la línea
        backgroundColor: 'rgba(255, 87, 51, 0.5)', // Color de fondo con opacidad
        fill: false, // No llenar el área bajo la línea
        tension: 0.1, // Curvatura de la línea
        pointBackgroundColor: '#FF5733',
        pointBorderColor: '#FF5733',
        pointRadius: 2,
        datalabels: {
          display: false // Esto elimina los datos del gráfico
        }
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: 'aliceblue', // Color blanco para los labels
          font: {
            size: 15 // Tamaño de letra de 1.5em
          }
        }
      },
      tooltip: {
        enabled: true,
        external: function(context) {
          const tooltipEl = document.getElementById('chartjs-tooltip');
          if (context.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          if (tooltipEl) {
            tooltipEl.style.opacity = 1;
            tooltipEl.style.left = context.chart.canvas.offsetLeft + context.tooltip.caretX + 'px';
            tooltipEl.style.top = context.chart.canvas.offsetTop + context.tooltip.caretY + 'px';
          }
        },
        callbacks: {
          label: function (context) {
            const label = context.label || '';
            const value = context.raw || 0;
            return isMobile ? '' : `${intl.formatMessage({id: "chart.Year"})}: ${label} - ${intl.formatMessage({id: "chart.Supply"})}: ${value.toFixed(2)} quintillones`;
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: intl.formatMessage({id: "chart.Year"}),
          color: 'aliceblue',
          font: {
            size: 15
          }
        },
        ticks: {
          color: 'aliceblue'
        }
      },
      y: {
        title: {
          display: !isMobile, // Mostrar el título solo si no es un dispositivo móvil
          text: intl.formatMessage({id: "chart.SupplyTitle"}),
          color: 'aliceblue',
          font: {
            size: 15
          }
        },
        ticks: {
          color: 'aliceblue'
        }
      }
    }
  };

  return (
    <div className="vrgdevurret"> 
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <div style={{ width: '90%', margin: '0.2em' }}>
          <h2 style={{ textAlign: 'center', margin: '0.5em' }}><FormattedMessage id="chart.h2road" defaultMessage="ROAD MAP" /></h2>
          <Line data={data} options={options} />
        </div>
        <div style={{ width: '90%', margin: '0.2em', color: 'aliceblue', textAlign: 'center' }}>
          <p><FormattedMessage id="chart.p1" defaultMessage="El primer año se quemará el 20% de los tokens." /></p>
          <p><FormattedMessage id="chart.p2" defaultMessage="El segundo año se quemará otro 20% de los tokens restantes." /></p>
          <p><FormattedMessage id="chart.p3" defaultMessage="El tercer año se quemará un 10% adicional de los tokens restantes." /></p>
        </div>
      </div>
      <div id="chartjs-tooltip" className="chartjs-tooltip"></div>
      <div className="perroverguero04">
      <h1><FormattedMessage id="chart.lineatiempo" defaultMessage="Linea Temporal" /></h1>
      <div className="timeline-container">
        <TimelineItem year="2024" content={intl.formatMessage({id: "chart.yearcontent2024"})} />
        <TimelineItem year="2025" content={intl.formatMessage({id: "chart.yearcontent2025"})}  />
        <TimelineItem year="2026" content={intl.formatMessage({id: "chart.yearcontent2026"})}  />
      </div>
      </div>
    </div>
  );
};

export default RoadToMapChart;
