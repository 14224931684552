import React, { useState, useEffect, useContext, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import './App.css';
import ConnectButton from './components/ConnectButton';
import homeIcon from './images/casa.png';
import profilesIcon from './images/pobre.png';
import moneyIcon from './images/delincuente.png';
import walletIcon from './images/arquero.png';
import aboutIcon from './images/pene.png';
import Home from './components/Home';
import Profiles from './components/Profiles';
import Buy from './components/Buy';
import Stake from './components/Stake';
import About from './components/About';
import Miner from './components/Miner';
import { FormattedMessage, useIntl } from 'react-intl';
import { LangContext } from './context/langContext';
import En from './images/estados-unidos.png';
import Es from './images/espana.png';
import Jp from './images/japon.png';
import Hi from './images/india.png';
import Fr from './images/francia.png';
import Ch from './images/porcelana.png';
import Ar from './images/bandera.png';
import Sz from './images/suiza.png';
import De from './images/alemania.png';
import Bs from './images/brasil.png';
import Ko from './images/corea-del-sur.png';
import Ru from './images/rusia.png';
import Venezuela from './images/venezuela.png';
import Argentina from './images/argentina.png';
import Mexico from './images/mexico.png';
import Salvador from './images/el-salvador.png';
import Chile from './images/chile.png';
import Ecuador from './images/ecuador.png';
import Peru from './images/peru.png';
import Colombia from './images/colombia.png';
import Cuba from './images/cuba.png';
import Uruguay from './images/uruguay.png';
import Bolivia from './images/bolivia.png';
import Republica from './images/republica-dominicana.png';
import Footer from './components/Footer';
import Disclaimer from './components/Disclaimer';
import { Fade } from 'react-reveal';

const App = () => {
  const [activeIndex, setActiveIndex] = useState(() => {
    const savedIndex = localStorage.getItem('activeIndex');
    return savedIndex !== null ? parseInt(savedIndex, 10) : 0;
  });
  const { establecerLenguaje } = useContext(LangContext);
  const intl = useIntl();

  const navItems = useMemo(() => [
    { name: 'Home', icon: homeIcon, path: '/' },
    { name: 'Perfil', icon: profilesIcon, path: '/profiles' },
    { name: 'Buy', icon: moneyIcon, path: '/buy' },
    { name: 'Stake', icon: walletIcon, path: '/stake' },
    { name: 'About', icon: aboutIcon, path: '/about' },
  ], []);

  const handleItemClick = (index) => {
    setActiveIndex(index);
    localStorage.setItem('activeIndex', index);
  };

  const Navigation = () => {
    const location = useLocation();

    useEffect(() => {
      const currentIndex = navItems.findIndex(item => item.path === location.pathname);
      if (currentIndex !== -1) {
        setActiveIndex(currentIndex);
        localStorage.setItem('activeIndex', currentIndex.toString());
      }
    }, [location.pathname, navItems]);

    return (
      <nav className="nav">
        <ul>
          {navItems.map((item, index) => (
            <li
              key={index}
              className={`list ${index === activeIndex ? 'active' : ''}`}
              onClick={() => handleItemClick(index)}
            >
              <Link to={item.path} className="nav-link tu-clase">
                <span className="icon">
                  <img src={item.icon} alt={item.name} />
                </span>
                <span className="text">
                  <FormattedMessage id={`nav.${item.name}`} defaultMessage={item.name} />
                </span>
              </Link>
            </li>
          ))}
          <div className="indicator"></div>
        </ul>
        <ConnectButton />
      </nav>
    );
  };

  useEffect(() => {
    Swal.fire({
      icon: 'info',
      title: intl.formatMessage({ id: 'alert.title' }),
      html: intl.formatMessage({ id: 'alert.message' }),
      footer: `<b>${intl.formatMessage({ id: 'alert.footer' })}</b>`,
      timer: 23000,
      customClass: {
        popup: 'swal-wide'
      }
    });
  }, [intl]);
  
  return (
    <Router>
      <div className="secure-APP">
        <div className="no-select">
          <Navigation />
          <div className="lenguaje">
            <button onClick={() => establecerLenguaje('en-US')} className="buttonlenguajeEn tu-clase">
              <img src={En} alt="English" />
            </button>
            <button  onClick={() => establecerLenguaje('es-MX')} className="buttonlenguajeEn tu-clase">
              <img src={Es} alt="Español" />
            </button>
            <button onClick={() => establecerLenguaje('ja-JP')} className="buttonlenguajeEn tu-clase">
              <img src={Jp} alt="日本語" />
            </button>
            <button  onClick={() => establecerLenguaje('fr-FR')} className="buttonlenguajeEn tu-clase">
              <img src={Fr} alt="Français" />
            </button>
            <button  onClick={() => establecerLenguaje('pt-PT')} className="buttonlenguajeEn tu-clase">
              <img src={Bs} alt="Português" />
            </button>
            <button onClick={() => establecerLenguaje('ko-KO')} className="buttonlenguajeEn tu-clase">
              <img src={Ko} alt="한국어" />
            </button>
            <button onClick={() => establecerLenguaje('zh-CN')} className="buttonlenguajeEn tu-clase">
              <img src={Ch} alt="中文" />
            </button>
            <button onClick={() => establecerLenguaje('nl-NL')} className="buttonlenguajeEn tu-clase">
              <img src={Sz} alt="Nederlands" />
            </button>
            <button  onClick={() => establecerLenguaje('ar-AR')} className="buttonlenguajeEn tu-clase">
              <img src={Ar} alt="العربية" />
            </button>
            <button onClick={() => establecerLenguaje('hi-HI')} className="buttonlenguajeEn tu-clase">
              <img src={Hi} alt="हिन्दी" />
            </button>
            <button  onClick={() => establecerLenguaje('de-DE')} className="buttonlenguajeEn tu-clase">
              <img src={De} alt="Deutsch" />
            </button>
            <button  onClick={() => establecerLenguaje('ru-RU')} className="buttonlenguajeEn tu-clase">
              <img src={Ru} alt="RU" />
            </button>
          </div>
          <div className="perraputainfame">
            <div className="perraputainfame-track">
              <div className="slide-container">
                <p className="slideputita"><img src={Venezuela} alt="Venezuela" /></p>
                <p className="slideputita"><img src={Argentina} alt="Argentina" /></p>
                <p className="slideputita"><img src={Salvador} alt="Salvador" /></p>
                <p className="slideputita"><img src={Cuba} alt="Cuba" /></p>
                <p className="slideputita"><img src={Colombia} alt="Colombia" /></p>
                <p className="slideputita"><img src={Uruguay} alt="Uruguay" /></p>
                <p className="slideputita"><img src={Bolivia} alt="Bolivia" /></p>
                <p className="slideputita"><img src={Peru} alt="Peru" /></p>
                <p className="slideputita"><img src={Ecuador} alt="Ecuador" /></p>
                <p className="slideputita"><img src={Chile} alt="Chile" /></p>
                <p className="slideputita"><img src={Mexico} alt="Mexico" /></p>
                <p className="slideputita"><img src={Republica} alt="Republica" /></p>
              </div>
            </div>
          </div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profiles" element={<Profiles />} />
            <Route path="/buy" element={<Buy />} />
            <Route path="/stake" element={<Stake />} />
            <Route path="/about" element={<About />} />
            <Route path="/miner" element={<Miner />} />
          </Routes>
        </div>
        <Fade bottom>
          <Disclaimer />
        </Fade>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
