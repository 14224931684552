import React from 'react';
import { ethers } from 'ethers'; 
import { contractAddress, contractToken } from './ContractInfo';
import { useAccount, useContractRead } from 'wagmi';
import { bsc } from 'wagmi/chains';
import '../Progreso.css';

const BarraProgreso = () => {
    const { address } = useAccount();

    const { data: tokensDisponibles } = useContractRead({
        abi: contractToken,
        address: contractAddress,
        functionName: 'availableTokens',
        chainId: bsc.id,
        account: address,
    });

    const { data: tokensTotales } = useContractRead({
        abi: contractToken,
        address: contractAddress,
        functionName: 'TOKENS_PER_STAGE',
        chainId: bsc.id,
        account: address,
    });

    const formatWeiToEther = (value) => {
        if (value) {
            return ethers.formatEther(value);
        }
        return '0';
    };

    const calcularPorcentaje = (disponibles, totales) => {
        if (!totales || totales === 0) return 0;
        return ((totales - disponibles) / totales) * 100;
    };

    const tokensDisponiblesEther = tokensDisponibles ? parseFloat(formatWeiToEther(tokensDisponibles)) : 0;
    const tokensTotalesEther = tokensTotales ? parseFloat(formatWeiToEther(tokensTotales)) : 0;
    const porcentaje = calcularPorcentaje(tokensDisponiblesEther, tokensTotalesEther);

    return (
        <div className="cobteiner-bar">
            <div className="Conteiner-barP">
        <p className="progress-label-letras">{formatWeiToEther(tokensDisponibles)} Pesos </p>
        </div>
        <div className="progress-container">
            <div className="progress-bar" style={{ width: `${porcentaje.toFixed(5)}%` }}></div>
            <p className="progress-label">{porcentaje.toFixed(5)}%</p>
        </div>
        </div>
    );
};

export default BarraProgreso;