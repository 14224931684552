// ConnectButton.js
import React, { useState, useEffect } from 'react';
import { useAccount, useDisconnect } from 'wagmi';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { FormattedMessage } from 'react-intl';

function ConnectButton() {
  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const [isConnecting, setIsConnecting] = useState(false);

  useEffect(() => {
    if (isConnected) {
      setIsConnecting(false);
    }
  }, [isConnected]);

  const handleConnect = async () => {
    if (isConnected) {
      disconnect();
    } else {
      setIsConnecting(true);
      await open();
    }
  };

  const formatAddress = (address) => {
    return `${address.slice(0, 4)}....${address.slice(-4)}`;
  };

  return (
    <div>
      <button className="custom-button" onClick={handleConnect} disabled={isConnecting}>
        {isConnecting ? (
          <FormattedMessage id="connectButton.connecting" defaultMessage="Connecting..." />
        ) : isConnected ? (
          formatAddress(address)
        ) : (
          <FormattedMessage id="connectButton.connected" defaultMessage="Connect Wallet" />
        )}
      </button>
    </div>
  );
}

export default ConnectButton;
