import React, { useState } from 'react';
import '../Timeline.css';

const Timeline = ({ year, content }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="timeline-item">
      <div className="year-container" onClick={toggleExpand}>
        <div className="year">{year}</div>
        <div className={`arrow ${expanded ? 'expanded' : ''}`}>&#x2B07;</div>
      </div>
      {expanded && (
        <div className="bubble">
          <div className="bubble-content">
            <p>{content}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Timeline;
