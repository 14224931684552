import React from 'react';
import { contractAddress, contractToken } from './ContractInfo';
import { useAccount, useContractRead } from 'wagmi';
import { bsc } from 'wagmi/chains';
import '../bank.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { Fade, Slide } from "react-reveal";

const Bank = () => {
  const intl = useIntl();
  const { address } = useAccount();

  const { data: balances } = useContractRead({
    abi: contractToken,
    address: contractAddress,
    functionName: 'getRemainingtoken',
    args: [address], 
    chainId: bsc.id,
  });

  const { data: balanceO } = useContractRead({
    abi: contractToken,
    address: contractAddress,
    functionName: 'balanceOf',
    args: [address], 
    chainId: bsc.id,
  });

  const { data: balanceRewards } = useContractRead({
    abi: contractToken,
    address: contractAddress,
    functionName: 'getCurrentRewardPerSecond', 
    chainId: bsc.id,
    account: address,
  });

  const formatWeiToEther = (value) => {
    if (value) {
      // Convert the value from Wei to Ether and truncate to remove decimals
      return Math.floor(parseFloat(value.toString()) / 1e18);
    }
    return '0';
  };
  

  return (
    <div className="bank-container">
      <Fade top>
      <h1 className="bank">
        <FormattedMessage id="bank.h1" defaultMessage="Banco" />
      </h1> 
      </Fade>
      <Slide right>
      <p className="letrasP" data-label={intl.formatMessage({ id: "bank.balancePesos" })}>
        {formatWeiToEther(balances)} PESOS
      </p>
      </Slide>
      <Slide left>
      <p className="letrasP" data-label={intl.formatMessage({ id: "bank.ganancias" })}>
        {formatWeiToEther(balanceRewards)} PESOS
      </p>
      </Slide>
      <Slide right>
      <p className="letrasP" data-label={intl.formatMessage({ id: "bank.balanceCartera" })}>
        {formatWeiToEther(balanceO)} PESOS
      </p>
      </Slide>
    </div>
  );
};

export default Bank;
