import '../items.css';
import Burro from '../images/burro.png';
import { FormattedMessage } from 'react-intl';

export const items = [
    <div className="carousel-item">
      <div className="carousel-content">
        <img src={Burro} alt="burro" />
        <h3><FormattedMessage id="item.R" defaultMessage="Retorno" /></h3>
        <p><FormattedMessage id="item.Rinfo" defaultMessage="*3 - 6%*, depende la inversión y referidos" /></p>
      </div>
    </div>,
    <div className="carousel-item">
      <div className="carousel-content">
        <img src={Burro} alt="burro" />
        <h3><FormattedMessage id="item.C" defaultMessage="Comisión" /></h3>
        <p><FormattedMessage id="item.Cinfo" defaultMessage="2% Deposito - 2% Retiro + 0.00051 WBNB de mantenimiento de pool" /></p>
      </div>
    </div>,
    <div className="carousel-item">
      <div className="carousel-content">
        <img src={Burro} alt="burro" />
        <h3><FormattedMessage id="item.RI" defaultMessage="Reinversión" /></h3>
        <p><FormattedMessage id="item.RIn" defaultMessage="Solo podras reinvertir Arriba de 0.017 WBNB" /></p>
      </div>
    </div>,

<div className="carousel-item">
<div className="carousel-content">
  <img src={Burro} alt="burro" />
  <h3><FormattedMessage id="item.IN" defaultMessage="Información" /></h3>
  <p><FormattedMessage id="item.INf" defaultMessage="Los Depositos minimos son de 0.005 WBNB y para obtener el 5% sobre los referidos deben depositar 0.2 WBNB " /></p>
</div>
</div>,

<div className="carousel-item">
<div className="carousel-content">
  <img src={Burro} alt="burro" />
  <h3><FormattedMessage id="item.CA" defaultMessage="Castigos" /></h3>
  <p><FormattedMessage id="item.Caf" defaultMessage="Los impuestos van desde el 90% del dia 0 hasta el 0% en el dia 10" /></p>
</div>
</div>,
];
