import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useIntl } from 'react-intl';

// Registrar elementos y plugins de Chart.js
Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const TokenomicsChart = () => {
  const intl = useIntl();
  const totalSupply = 300; // 300 quintillones

  const data = {
    labels: [
      intl.formatMessage({id: "chart.gift"}),
      intl.formatMessage({id: "chart.staking"}),
      intl.formatMessage({id: "chart.salesContract"}),
      intl.formatMessage({id: "chart.exchangeListing"}),
      intl.formatMessage({id: "chart.publicSale"})
    ],
    datasets: [
      {
        label: 'Tokenomics',
        data: [
          90, // 30% para regalar
          60, // 20% para staking
          30, // 10% para contrato de ventas
          60, // 20% para listado en exchanges
          60  // 20% para venta pública
        ],
        backgroundColor: [
          'rgba(255, 87, 51, 0.8)', // Nuevo color rojo
          'rgba(52, 152, 219, 0.8)', // Nuevo color azul
          'rgba(255, 206, 86, 0.8)',
          'rgba(210, 105, 30, 0.8)', // Nuevo color chocolate
          'rgba(153, 102, 255, 0.8)'
        ],
        hoverBackgroundColor: [
          'rgba(255, 87, 51, 1)', // Nuevo color rojo
          'rgba(52, 152, 219, 1)', // Nuevo color azul
          'rgba(255, 206, 86, 1)',
          'rgba(210, 105, 30, 1)', // Nuevo color chocolate
          'rgba(153, 102, 255, 1)'
        ],
        borderWidth: 1,
        borderColor: [
          'rgba(255, 87, 51, 1)', // Nuevo color rojo
          'rgba(52, 152, 219, 1)', // Nuevo color azul
          'rgba(255, 206, 86, 1)',
          'rgba(210, 105, 30, 1)', // Nuevo color chocolate
          'rgba(153, 102, 255, 1)'
        ],
        borderAlign: 'inner',
        datalabels: {
          display: false // Esto elimina los datos del círculo
        }
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: 'aliceblue', // Color blanco para los labels
          font: {
            size: 15 // Tamaño de letra de 1.5em
          }
        }
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const label = context.label || '';
            const value = context.raw || 0;
            const percentage = ((value / totalSupply) * 100).toFixed(2) + '%';
            return label + ': ' + value + ' quintillones (' + percentage + ')';
          }
        }
      }
    },
    elements: {
      arc: {
        borderWidth: 1, // Ancho del borde
        borderColor: '#fff' // Color del borde
      }
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ width: '21.5rem', margin: '0.2em' }}>
        <h2 style={{ textAlign: 'center', margin: '0.5em' }}>Tokenomics</h2>
        <Pie data={data} options={options} />
      </div>
    </div>
  );
};

export default TokenomicsChart;
