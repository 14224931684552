import React, { useState } from 'react';
import '../Disclaimer.css';
import { FaTimes } from 'react-icons/fa'; // Importamos el ícono de cierre
import { FormattedMessage } from 'react-intl';

const Disclaimer2 = () => {
    const [isVisible, setIsVisible] = useState(true);

    const handleClose = () => {
        setIsVisible(false);
    };

    return (
        isVisible && (
            <div className="disclaimer-container">
                <button className="disclaimer-close" onClick={handleClose}>
                    <FaTimes />
                </button>
                <p className="disclaimer-text">
                <FormattedMessage id="Disclaimer2.alrt" defaultMessage="No, no te robamos. Los tokens están en stake mientras el peso sale al mercado. Con tu enlace de referido, podrás conseguir un 5% por cada compra que obtengas en WBNB, directo a tu wallet. ¡No puedes comprar más tokens de los que quedan en la etapa!" />
                </p>
            </div>
        )
    );
};

export default Disclaimer2;
