// Miner.js
import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers'; // Asegúrate de tener ethers.js instalado correctamente
import { contractAddress2, contractToken2 } from './ContractInfo2';
import { contractAddress3, contractToken3 } from './ContractInfo3';
import { useAccount, useContractRead, useContractWrite } from 'wagmi';
import { bsc } from 'wagmi/chains';
import { FormattedMessage, useIntl } from 'react-intl';
import '../Miner.css';
import Disclaimer3 from './Disclaimer3';
import Regreso from './Regreso';
import Carousel from './Carousel';
import {items} from './items';
import { Fade, Slide } from "react-reveal";

const Miner = () => {
  const intl = useIntl();
  const { address } = useAccount();
  const [amount, setAmount] = useState('');
  const [amountInWei2, setamountInWei2] = useState('');
  const [referralAddress2, setReferralAddress2] = useState(address); // Inicialmente igual a la dirección del usuario conectado

  useEffect(() => {
    // Función para obtener y establecer referralAddress2 desde el parámetro 'id' en la URL
    const getReferralAddressFromURL = () => {
      const params = new URLSearchParams(window.location.search);
      const wallet = params.get('id');
      if (wallet) {
        setReferralAddress2(wallet);
      } else {
        setReferralAddress2(address); // Si no hay parámetro 'id', usar la dirección del usuario conectado
      }
    };

    // Llamar a la función al cargar el componente
    getReferralAddressFromURL();
  }, [address]); // Dependencia en 'address' para asegurar que se actualiza si la dirección del usuario cambia

  const { data: balanceInvestMiner } = useContractRead({
    abi: contractToken3,
    address: contractAddress3,
    functionName: 'bnbTvl',
    args: [address],
    chainId: bsc.id,
  });

  const { data: balanceRewardsMiner } = useContractRead({
    abi: contractToken3,
    address: contractAddress3,
    functionName: 'bnbRewards',
    args: [address],
    chainId: bsc.id,
  });

  const { data: BEANS } = useContractRead({
    abi: contractToken3,
    address: contractAddress3,
    functionName: 'totalBakers',
    chainId: bsc.id,
    account: address,
  });

  const { data: TIMEC } = useContractRead({
    abi: contractToken3,
    address: contractAddress3,
    functionName: 'secondsSinceLastEat',
    chainId: bsc.id,
    args: [address],
  });

  const segundosFunction = (seconds) => {
    const secondsPerDay = 86400;
    if (Number(seconds) > 0) {
      return Math.floor(Number(seconds) / secondsPerDay);
    } else {
      return '0';
    }
  };
  
  const { data: contractBalance } = useContractRead({
    abi: contractToken3,
    address: contractAddress3,
    functionName: 'contractBalance',
    chainId: bsc.id,
    account: address,
  });

  const formatWeiToEther = (value) => {
    if (value) {
      return ethers.formatEther(value);
    }
    return '0';
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmount(value);
    if (value) {
      try {
        const valueInWei = ethers.parseUnits(value, 'ether').toString();
        console.log('Converted Value in Wei:', valueInWei);
        setamountInWei2(valueInWei);
      } catch (error) {
        console.error("Error converting to wei:", error);
      }
    } else {
      setamountInWei2('');
    }
  };

  const { write: approve } = useContractWrite({
    abi: contractToken2,
    address: contractAddress2,
    functionName: 'approve',
    args: [
      '0xCe73a6c611dE1E4525462dF1bfCd8CC1d10c21a6',
      '5000000000000000000000000000000000000000000000000',
    ],
    chainId: bsc.id,
  });

  const { write: buyBEANS } = useContractWrite({
    abi: contractToken3,
    address: contractAddress3,
    functionName: 'buyBeans',
    args: [referralAddress2, amountInWei2],
    chainId: bsc.id,
    gas:300000n,
    onError(error) {
      console.error("Error en buyBEANS:", error);
    },
  });

  const { write: ComerTokens } = useContractWrite({
    abi: contractToken3,
    address: contractAddress3,
    functionName: 'eat',
    args: [amountInWei2],
    chainId: bsc.id,
    gas:300000n,
  });

  const { write: ReTokens } = useContractWrite({
    abi: contractToken3,
    address: contractAddress3,
    functionName: 'bake',
    chainId: bsc.id,
    gas:300000n,
  });

  const generateReferralLink = () => {
    return `https://socialismcoin.info/miner?id=${referralAddress2}`;
  };

  const copyToClipboard = () => {
    const referralLink = generateReferralLink();
    navigator.clipboard.writeText(referralLink).then(() => {
      alert(intl.formatMessage({ id: 'stake.copied', defaultMessage: 'Link copiado al portapapeles' }));
    }).catch(err => {
      console.error('Error al copiar el link:', err);
    });
  };

  const formatAddress = (address) => {
    if (address) {
      return `${address.slice(0, 4)}...${address.slice(-4)}`;
    }
    return '';
  };

  useEffect(() => {
    console.log('Referral Address:', referralAddress2);
    console.log('Amount in Wei:', amountInWei2);
  }, [referralAddress2, amountInWei2]);

  return (
    <>
    <Regreso />
      <Fade top>
        <Disclaimer3 />
      </Fade>
      <div className="Miner-container">
        <div className="Miner">
          <Slide left>
            <p className="letrasMi" data-label={intl.formatMessage({ id: "Miner.balanceInvertido" })}>
              {formatWeiToEther(balanceInvestMiner)} WBNB
            </p>
          </Slide>
          <p className="letrasMi" data-label={intl.formatMessage({ id: "Miner.rewards" })}>
            {formatWeiToEther(balanceRewardsMiner)} WBNB
          </p>
          <Slide right>
            <p className="letrasMi" data-label={intl.formatMessage({ id: "miner.balanceContrato" })}>
              {formatWeiToEther(contractBalance)} WBNB
            </p>
          </Slide>
          <Slide right>
            <p className="letrasMi" data-label={intl.formatMessage({ id: "Miner.beans" })}>
              {BEANS?.toString()}
            </p>
          </Slide>
          <Slide right>
            <p className="letrasMi" data-label={intl.formatMessage({ id: "Miner.TIME" })}>
            {segundosFunction(TIMEC)}
            </p>
          </Slide>
        </div>
        <div className="referidos">
          <Slide right>
            <button className="ButtomMiner2 tu-clase" onClick={copyToClipboard}>
              <FormattedMessage id="Miner.copyReferralLink" defaultMessage="Link de Referido" />
            </button>
          </Slide>
          <Slide left>
            {referralAddress2 && (
              <input
                className="InputMiner"
                type="text"
                value={formatAddress(referralAddress2)}
                readOnly
              />
            )}
          </Slide>
        </div>
        <Fade top>
          <div className="Tortilla2">
            <div className="mancha2 mancha12"></div>
            <div className="mancha2 mancha22"></div>
            <div className="mancha2 mancha32"></div>
            <div className="mancha2 mancha42"></div>
            <div className="mancha2 mancha52"></div>
            <button className="ButtomMiner" onClick={() => approve()}>
              <FormattedMessage id="Miner.buttom" defaultMessage="Aprobar" />
            </button>
            <input
              className="InputMiner"
              type="number"
              value={amount}
              onChange={handleAmountChange}
              placeholder={intl.formatMessage({ id: "Miner.placeholder" })}
            />
            <button className="ButtomMiner tu-clase" onClick={() => buyBEANS()}>
              <FormattedMessage id="Miner.buttom1" defaultMessage="Comprar" />
            </button>
          </div>
          <div className="comerandreinvest">
          <button className="ButtomMiner tu-clase" onClick={() => ComerTokens()}>
              <FormattedMessage id="Miner.buttom2" defaultMessage="Comer" />
            </button>
            <button className="ButtomMiner tu-clase" onClick={() => ReTokens()}>
              <FormattedMessage id="Miner.buttom3" defaultMessage="Reinvertir" />
            </button>
          </div>
        </Fade>
        <Carousel items={items} />
      </div>
    </>
  );
};

export default Miner;