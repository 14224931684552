import React, { useState } from 'react';
import '../Disclaimer.css';
import { FaTimes } from 'react-icons/fa'; // Importamos el ícono de cierre
import { FormattedMessage } from 'react-intl';


const Disclaimer = () => {
    const [isVisible, setIsVisible] = useState(true);

    const handleClose = () => {
        setIsVisible(false);
    };

    return (
        isVisible && (
            <div className="disclaimer-container">
                <button className="disclaimer-close" onClick={handleClose}>
                    <FaTimes />
                </button>
                <p className="disclaimer-text">
                <FormattedMessage id="Disclaimer.alrt" defaultMessage="No somos Hamster Kombat ni otro tonto proyecto de TON. Aquí no tendrás que referir hasta a tu abuela para ganar. Somos de la red de Binance Smart Chain y te incluiremos en el primer proyecto de criptomonedas impulsado y creado por la comunidad. Pero, aun así, recuerda que si inviertes en criptomonedas, puedes perder las nalgas." />
                </p>
            </div>
        )
    );
};

export default Disclaimer;
