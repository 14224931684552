import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa'; // Importamos la flecha de react-icons
import '../Regreso.css'; // Importamos el archivo de estilos CSS

const Regreso = () => {
  return (
    <>
      <Link to="/" className="back-link">
        <FaArrowLeft className="back-icon" />
      </Link>
    </>
  );
};

export default Regreso;
