// Footer.js
import React from 'react';
import '../Footer.css';
import { Fade } from "react-reveal";


const Footer = () => {
  return (
    <footer className="footer-container tu-clase">
      <div className="footer-socials">
      <Fade Bottom>
        <a href="https://x.com/SocialismCoin" className="footer-link" target="_blank" rel="noopener noreferrer">Twitter</a>
        </Fade>
        <Fade Bottom>
        <a href="https://t.me/Socialism_Coin" className="footer-link" target="_blank" rel="noopener noreferrer">Telegram</a>
        </Fade>
        <Fade Bottom>
        <a href="https://bscscan.com/address/0x759bF88A0Df2dCA660109E2DD013aABaeAC9aC3C" className="footer-link" target="_blank" rel="noopener noreferrer">Contract</a>
        </Fade>
      </div>
      <div className="footer-rights">
        &copy; {new Date().getFullYear()} SOCIALIMS COIN. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
