import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers'; // Asegúrate de tener ethers.js instalado correctamente
import { contractAddress, contractToken } from './ContractInfo';
import { contractAddress2, contractToken2 } from './ContractInfo2';
import { useAccount, useContractRead, useContractWrite } from 'wagmi';
import { bsc } from 'wagmi/chains';
import { FormattedMessage, useIntl } from 'react-intl';
import '../Buy.css';
import Disclaimer2 from './Disclaimer2';
import Bprogreso from './barraprogeso';
import { Fade, Slide } from "react-reveal";

const Buy = () => { 
  const intl = useIntl();
  const { address } = useAccount();
  const [amount, setAmount] = useState('');
  const [amountInWei, setAmountInWei] = useState('');
  const [referralAddress, setReferralAddress] = useState(address); // Inicialmente igual a la dirección del usuario conectado

  useEffect(() => {
    // Función para obtener y establecer referralAddress desde el parámetro 'id' en la URL
    const getReferralAddressFromURL = () => {
      const params = new URLSearchParams(window.location.search);
      const wallet = params.get('id');
      if (wallet) {
        setReferralAddress(wallet);
      }
    };

    // Llamar a la función al cargar el componente
    getReferralAddressFromURL();
  }, []);

  const { data: balanceinevestBuy } = useContractRead({
    abi: contractToken,
    address: contractAddress,
    functionName: 'balancesBuyT',
    args: [address],
    chainId: bsc.id,
  });

  const { data: precio } = useContractRead({
    abi: contractToken,
    address: contractAddress,
    functionName: 'currentPrice',
    chainId: bsc.id,
    account: address,
  });

  const { data: Etapa } = useContractRead({
    abi: contractToken,
    address: contractAddress,
    functionName: 'currentStage',
    chainId: bsc.id,
    account: address,
  });

  const { data: contractbalance } = useContractRead({
    abi: contractToken,
    address: contractAddress,
    functionName: 'contractBalance',
    chainId: bsc.id,
    account: address,
  });

  const { data: tlvBuy } = useContractRead({
    abi: contractToken,
    address: contractAddress,
    functionName: 'WBNBBalance',
    chainId: bsc.id,
    account: address,
  });

  const formatWeiToEther = (value) => {
    if (value) {
      return ethers.formatEther(value);
    }
    return '0';
  };

  const formatWeiToEtherSin = (value) => {
    if (value) {
      // Convert the value from Wei to Ether and truncate to remove decimals
      return Math.floor(parseFloat(value.toString()) / 1e18);
    }
    return '0';
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmount(value);
    if (value) {
      try {
        const valueInWei = ethers.parseUnits(value, 'ether').toString();
        setAmountInWei(valueInWei);
      } catch (error) {
        console.error("Error converting to wei:", error);
      }
    } else {
      setAmountInWei('');
    }
  };

  const { write: approve } = useContractWrite({
    abi: contractToken2,
    address: contractAddress2,
    functionName: 'approve',
    args: [
      '0x759bF88A0Df2dCA660109E2DD013aABaeAC9aC3C',
      '5000000000000000000000000000000000000000000000000',
    ],
    chainId: bsc.id,
  });

  const { write: buyTokens } = useContractWrite({
    abi: contractToken,
    address: contractAddress,
    functionName: 'buyTokens',
    args: [amountInWei, referralAddress],
    chainId: bsc.id,
  });


  const generateReferralLink = () => {
    return `https://socialismcoin.info/buy?id=${referralAddress}`;
  };

  const copyToClipboard = () => {
    const referralLink = generateReferralLink();
    navigator.clipboard.writeText(referralLink).then(() => {
      alert(intl.formatMessage({ id: 'stake.copied', defaultMessage: 'Link copiado al portapapeles' }));
    }).catch(err => {
      console.error('Error al copiar el link:', err);
    });
  };

  const formatAddress = (address) => {
    if (address) {
      return `${address.slice(0, 4)}...${address.slice(-4)}`;
    }
    return '';
  };

  return (
    <>
    <Fade top>
      <Disclaimer2 />
      </Fade>
      <div className="Buy-container">
        <div className="BuY">
        <Slide left>
          <p className="letrasB" data-label={intl.formatMessage({ id: "buy.balanceInvertido" })}>
            {formatWeiToEtherSin(balanceinevestBuy)} PESOS
          </p>
          </Slide>
          <p className="letrasB" data-label={intl.formatMessage({ id: "buy.precio" })}>
            {formatWeiToEther(precio)} WBNB
          </p>
          <Slide right>
          <p className="letrasB" data-label={intl.formatMessage({ id: "stake.balanceContrato" })}>
            {formatWeiToEtherSin(contractbalance)} PESOS
          </p>
          </Slide>
          <Slide left>
          <p className="letrasB" data-label={intl.formatMessage({ id: "buy.tlv" })}>
            {formatWeiToEther(tlvBuy)} WBNB
          </p>
          </Slide>
          <Slide right>
          <p className="letrasB" data-label={intl.formatMessage({ id: "buy.etapa" })}>
            {Etapa?.toString()}
          </p>
          </Slide>
        </div>
        <div className="referidos">
          <Slide right>
          <button className="ButtomBuY tu-clase" onClick={copyToClipboard}>
            <FormattedMessage id="buy.copyReferralLink" defaultMessage="Link de Referido" />
          </button>
          </Slide>
          <Slide left>
          {referralAddress && (
            <input
              className="InputBuY"
              type="text"
              value={formatAddress(referralAddress)}
              readOnly
            />
          )}
          </Slide>
        </div>
        <Fade bottom>
        <Bprogreso />
        </Fade>
        <Fade top>
        <div className="Tortilla">
          <div className="mancha mancha1"></div>
          <div className="mancha mancha2"></div>
          <div className="mancha mancha3"></div>
          <div className="mancha mancha4"></div>
          <div className="mancha mancha5"></div>
          <button className="ButtomBuY" onClick={() => approve()}>
            <FormattedMessage id="Stake.buttom" defaultMessage="Aprobar" />
          </button>
          <input
            className="InputBuY"
            type="number"
            value={amount}
            onChange={handleAmountChange}
            placeholder={intl.formatMessage({ id: "stake.placeholder" })}
          />
          <button className="ButtomBuY tu-clase" onClick={() => buyTokens()}>
            <FormattedMessage id="buy.buttom1" defaultMessage="Comprar" />
          </button>
        </div>
        </Fade>
      </div>
    </>
  );  
};

export default Buy;
