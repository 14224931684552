import React, { useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { FormattedMessage } from 'react-intl';
import '../AccordionItem.css';

const AccordionItem = ({ preguntaId, respuestaId }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleAccordion = () => {
        setExpanded(!expanded);
    };

    return (
        <div className="accordion-item tu-clase">
            <div className="accordion-question" onClick={toggleAccordion}>
                <h3>
                    <FormattedMessage id={preguntaId} />
                </h3>
                <div className="chevron-icon">
                    {expanded ? <BsChevronUp /> : <BsChevronDown />}
                </div>
            </div>
            {expanded && (
                <div className="accordion-answer">
                    <p className="answer-text">
                        <FormattedMessage id={respuestaId} />
                    </p>
                </div>
            )}
        </div>
    );
};

export default AccordionItem;
